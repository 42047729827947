import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, FormGroup, Label, Input } from 'reactstrap'; // Bootstrap bileşenlerini ekleyin

const CreateJob = () => {
  const [jobType, setJobType] = useState('MISIR');
  const [taskName, setTaskName] = useState('');
  const [message, setMessage] = useState('');
  const [jobs, setJobs] = useState([]); // İşleri tutan state
  const [editingJob, setEditingJob] = useState(null); // Düzenlenen iş

  // Sayfa yüklendiğinde işleri listele
  useEffect(() => {
    fetchJobs();
  }, []);

  // İşleri veritabanından çek
  const fetchJobs = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/get-jobs');
      setJobs(response.data);
    } catch (error) {
      console.error("İşler alınırken hata:", error);
    }
  };

  // İş oluşturma fonksiyonu
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!taskName) {
      alert('İş adı zorunludur');
      return;
    }

    try {
      if (editingJob) {
        // Düzenleme modundaysak mevcut işi güncelle
        await axios.put(`${process.env.REACT_APP_API_URL}/update-job/${editingJob.job_id}`, {
          job_type: jobType,
          task_name: taskName,
        });
        setMessage("İş başarıyla güncellendi!");
        setEditingJob(null);
      } else {
        // Yeni iş oluştur
        await axios.post(process.env.REACT_APP_API_URL + '/create-job', {
          job_type: jobType,
          task_name: taskName,
        });
        setMessage("İş başarıyla oluşturuldu!");
      }

      fetchJobs(); // İş listesini güncelle
      setTaskName('');
    } catch (error) {
      console.error('İş oluşturulurken hata:', error);
      setMessage('İş oluşturulamadı');
    }
  };

  // İş düzenleme
  const handleEdit = (job) => {
    setEditingJob(job);
    setJobType(job.job_type);
    setTaskName(job.task_name);
  };

  // İş silme
  const handleDelete = async (jobId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-job/${jobId}`);
      fetchJobs(); // İş listesini güncelle
      setMessage("İş başarıyla silindi!");
    } catch (error) {
      console.error("İş silinirken hata:", error);
      setMessage("İş silinemedi!");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Yeni İş Oluştur</h2>
      {message && <div className="alert alert-info">{message}</div>}

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="jobType">İş Türü</Label>
          <Input
            type="text"
            id="jobType"
            value={jobType}
            onChange={(e) => setJobType(e.target.value)}
            placeholder="İş Türü Pancar-Patates vb."
          >
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="taskName">İş Adı</Label>
          <Input
            type="text"
            id="taskName"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            placeholder="İş adı girin"
          />
        </FormGroup>

        <Button type="submit" color="primary" block>
          {editingJob ? "İşi Güncelle" : "İş Oluştur"}
        </Button>
      </Form>

      <h2 className="mt-5">İş Listesi</h2>
      <Table bordered hover responsive className="mt-3">
        <thead className="thead-dark">
          <tr>
            <th>İş Adı</th>
            <th>İş Türü</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.job_id}>
              <td>{job.task_name}</td>
              <td>{job.job_type}</td>
              <td>
                <Button color="warning" onClick={() => handleEdit(job)} className="mr-2">
                  Düzenle
                </Button>
                <Button color="danger" onClick={() => handleDelete(job.job_id)}>
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CreateJob;
