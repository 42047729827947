import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import '../App.css'; // Stil dosyası ekledik

const ReportDashboard = () => {
  const { user } = useAuth0();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [dailyDispatches, setDailyDispatches] = useState(0);
  const [dailyOperatorReport, setDailyOperatorReport] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedDate) {
      fetchReports(selectedDate);
    }
  }, [selectedDate]);

  const fetchReports = async (date) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const formattedDate = new Date(date).toISOString().split('T')[0];

      const totalDispatchesResponse = await axios.get(`${apiUrl}/total-daily-dispatches`, {
        params: {
          date: formattedDate,
        }
      });

      const dailyOperatorResponse = await axios.get(`${apiUrl}/daily-operator-report`, {
        params: {
          date: formattedDate,
        }
      });

      setDailyDispatches(totalDispatchesResponse.data.total_dispatches);
      setDailyOperatorReport(dailyOperatorResponse.data);

    } catch (error) {
      setError('Veriler alınırken bir hata oluştu.');
      console.error('Error fetching reports:', error);
    }
  };

  const operatorNineData = dailyOperatorReport.find(report => report.operator_id === 9);
  const numberOfAbsentVehicles = operatorNineData ? operatorNineData.total_dispatches : 0;
  const totalLoadedVehicles = dailyDispatches - numberOfAbsentVehicles;

  return (
    <div className="report-dashboard-container">
      <h1 className="report-title">Raporlar</h1>
      
      {error && <p className="error-message">{error}</p>}

      {/* Tarih Seçici */}
      <div className="date-picker-container">
        <label htmlFor="date-picker">Tarih Seçin: </label>
        <input
          id="date-picker"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
      </div>

      {/* Günlük Görev Sayısı Raporu */}
      <section className="report-section">
        <h2>Seçilen Tarih İçin Toplam Görev Sayısı</h2>
        <p>Aranan Araç Sayısı: {dailyDispatches}</p>
        <p>Gelmeyen Araç Sayısı: {numberOfAbsentVehicles}</p>
        <p>Yüklenen Araç Sayısı: {totalLoadedVehicles}</p>
      </section>

      {/* Günlük Operatör Bazlı Rapor */}
      <section className="report-section">
        <h2>Seçilen Tarih İçin Operatör Bazlı Rapor</h2>
        <ul className="operator-report-list">
          {dailyOperatorReport.map((report) => (
            <li key={report.operator_id}>
              ID: {report.operator_id}, Operatör Adı: {report.operator_name}, Toplam: {report.total_dispatches}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ReportDashboard;