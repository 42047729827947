import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App.js";
import * as serviceWorker from "./serviceWorker.js";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history.js";
import { getConfig } from "./config.js";
import 'bootstrap/dist/css/bootstrap.min.css';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
  useRefreshTokens: true,  // Refresh Token kullanımı
  cacheLocation: "localstorage",  // Yerel saklama alanı olarak localStorage kullanılıyor
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider {...providerConfig}>
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
);

serviceWorker.unregister();
