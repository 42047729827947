import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Input, Grid, Paper, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const VehicleOrder = () => {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      console.log('Fetching vehicles...');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleDispatchList`);
      console.log('Response:', response.data);
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedVehicles = Array.from(vehicles);
    const [removed] = reorderedVehicles.splice(result.source.index, 1);
    reorderedVehicles.splice(result.destination.index, 0, removed);

    // Update order_number based on new order
    const updatedVehicles = reorderedVehicles.map((vehicle, index) => ({
      ...vehicle,
      order_number: index + 1
    }));

    setVehicles(updatedVehicles);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/updateVehicleDispatchList`, { vehicles: updatedVehicles });
    } catch (error) {
      console.error('Error updating vehicle order:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "order_number"
      },
      {
        Header: "Plaka",
        accessor: "plaka"
      },
      {
        Header: "Şoför İsmi",
        accessor: "driver_name"
      },
      {
        Header: "Araç Tipi",
        accessor: "vehicle_type"
      },
      {
        Header: "Römork Tipi",
        accessor: "trailer_type"
      }
    ],
    [] // No dependencies
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: vehicles
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Araç Listesi
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={state.globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Ara..."
          fullWidth
          style={{ marginBottom: '10px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ overflowX: 'auto' }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="vehicles">
              {(provided) => (
                <table {...getTableProps()} ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ border: '1px solid black', padding: '5px' }}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} style={{ textAlign: 'center', padding: '5px' }}>
                          Veri bulunamadı
                        </td>
                      </tr>
                    ) : (
                      rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Draggable key={row.original.vehicle_id} draggableId={row.original.vehicle_id.toString()} index={index}>
                            {(provided) => (
                              <tr {...row.getRowProps()} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {row.cells.map(cell => (
                                  <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '5px' }}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        );
                      })
                    )}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VehicleOrder;