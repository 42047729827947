import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';  
import { Button, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css'; // AG Grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // AG Grid Tema CSS

const AddVehicleToJob = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [tasks, setTasks] = useState([]);
  const [vehicles, setVehicles] = useState([]);  // Mevcut araç listesi
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  useEffect(() => {
    fetchTasks();
    fetchVehicles();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/getTasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Görevler alınırken hata oluştu:', error);
    }
  };

  const fetchVehicles = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/getVehiclesInList');
      setVehicles(response.data);
    } catch (error) {
      console.error('Araçlar alınırken hata oluştu:', error);
    }
  };

  const onSubmit = async (data) => {
    if (selectedVehicles.length === 0) {
      console.error('Hiç araç seçilmedi.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addVehiclesToJob`, {
        job_id: data.task,
        vehicles: selectedVehicles.map((id) => ({ vehicle_id: id }))
      });

      console.log('Liste oluşturuldu:', response.data.message);

      // Başarılı POST işleminden sonra, seçilen araçları tablodan çıkar
      const updatedVehicles = vehicles.filter(vehicle => 
        !selectedVehicles.includes(vehicle.vehicle_id)
      );
      setVehicles(updatedVehicles);  // Araç listesini güncelle
      setSelectedVehicles([]);  // Seçilen araçları temizle

    } catch (error) {
      console.error('Liste oluşturulurken hata oluştu:', error.response ? error.response.data : error.message);
    }
  };

  const deleteVehicle = async (vehicleId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/deleteVehicleFromList/${vehicleId}`);
      setVehicles(vehicles.filter(vehicle => vehicle.vehicle_id !== vehicleId));
      console.log('Araç silindi:', vehicleId);
    } catch (error) {
      console.error('Araç silinirken hata oluştu:', error);
    }
  };

  const columnDefs = [
    { headerName: 'S.No', field: 'order_number' },
    { headerName: 'Plaka', field: 'plaka' },
    { headerName: 'Şoför İsmi', field: 'driver_name' },
    { headerName: 'Araç Tipi', field: 'vehicle_type' },
    { headerName: 'Römork Tipi', field: 'trailer_type' },
    {
      headerName: 'Sil',
      field: 'delete',
      cellRendererFramework: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => deleteVehicle(params.data.vehicle_id)}
        >
          Sil
        </Button>
      )
    }
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedVehicles(selectedRows.map((row) => row.vehicle_id));
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Araçları Seç ve Listeyi Oluştur
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="task-select-label">İş Adı</InputLabel>
          <Select
            labelId="task-select-label"
            {...register('task', { required: true })}
            defaultValue=""
          >
            {tasks.map((task) => (
              <MenuItem key={task.job_id} value={task.job_id}>
                {task.task_name}
              </MenuItem>
            ))}
          </Select>
          {errors.task && <Typography color="error">İş Adı gerekli</Typography>}
        </FormControl>

        {/* AG Grid ile veri gösterimi */}
        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: '100%', marginTop: 20 }}
        >
          <AgGridReact
            rowData={vehicles}               // Araç verileri
            columnDefs={columnDefs}           // Kolon tanımlamaları
            defaultColDef={defaultColDef}     // Varsayılan kolon özellikleri
            rowSelection="multiple"           // Çoklu seçim
            onSelectionChanged={onSelectionChanged} // Seçim değişikliği olayı
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <Button variant="contained" color="primary" type="submit" style={{ marginRight: 10 }}>
            Listeyi Oluştur
          </Button>
          <Button variant="outlined" color="secondary">
            İptal
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddVehicleToJob;