import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, TextField, Button, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';


const StatusManagement = () => {
  const [statuses, setStatuses] = useState([]);
  const [newStatusName, setNewStatusName] = useState('');

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/statuses`);
        setStatuses(response.data);
      } catch (error) {
        console.error('Hata:', error);
      }
    };

    fetchStatuses();
  }, []);

  const handleAddStatus = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/statuses`, { status_name: newStatusName });
      setStatuses([...statuses, response.data]);
      setNewStatusName('');
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const handleDeleteStatus = async (status_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/statuses/${status_id}`);
      setStatuses(statuses.filter(status => status.status_id !== status_id));
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const handleEditStatus = async (status_id, status_name) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/statuses/${status_id}`, { status_name });
      setStatuses(statuses.map(status => (status.status_id === status_id ? { ...status, status_name } : status)));
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const columns = [
    { field: 'status_id', headerName: 'ID', width: 90 },
    {
      field: 'status_name',
      headerName: 'Status Name',
      width: 150,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteStatus(params.row.status_id)}
            size="small"
          >
            SİL
          </Button>
        </div>
      ),
    },
  ];

  const handleProcessRowUpdate = (newRow) => {
    handleEditStatus(newRow.status_id, newRow.status_name);
    return newRow;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ fontSize: '1.5rem' }}>
        Durum Yönetimi
      </Typography>
      <Box mb={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Grid container spacing={2} alignItems="center" style={{ border: 'none' }}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Yeni Durum"
              value={newStatusName}
              onChange={(e) => setNewStatusName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              slotProps={{
                inputLabel: { style: { fontSize: '0.875rem' } },
                input: { style: { fontSize: '0.875rem' } },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" color="primary" onClick={handleAddStatus} fullWidth size="small">
              Durum Ekle
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Paper style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={statuses}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          processRowUpdate={handleProcessRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.status_id}
          sx={{
            '& .MuiDataGrid-root': {
              fontSize: '0.875rem',
            },
            '& .MuiDataGrid-columnHeaders': {
              fontSize: '0.875rem',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.875rem',
            },
            '& .MuiButton-root': {
              fontSize: '0.75rem',
            },
          }}
        />
      </Paper>
    </Container>
  );
};

export default StatusManagement;