import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import LocationTracker from "./components/LocationTracker";
import Home from "./views/Home";
import Profile from "./views/Profile";
import VehicleList from "./views/VehicleList";
import VehicleOrder from "./views/VehicleOrder";
import VehicleOrder1 from "./views/VehicleOrder1";
import AddVehicleToList from "./views/AddVehicleToList";
import CreateJob from "./views/CreateJob";
import CreateNewListNo from "./views/CreateNewListNo";
import AddVehicleToJob from "./views/AddVehicleToJob";
import JobVehicleList from "./views/JobVehicleList";
import UpdateJobVehicleList from "./views/UpdateJobVehicleList";
import VehicleDispatchPage from "./views/VehicleDispatchPage";
import StatusManagement from "./views/StatusManagement";
import OperatorManagement from "./views/OperatorManagement";
import OperatorDispatchesPage from "./views/OperatorDispatchesPage";
import UserReportPage from "./views/UserReportPage";
import ReportDashboard from "./views/ReportDashboard";
import DriverStatusPage from "./views/DriverStatusPage";
import OperatorLocationPage from "./views/OperatorLocationPage";
import { useAuth0 } from "@auth0/auth0-react";
import initFontAwesome from "./utils/initFontAwesome";
import manageDeviceId from "./utils/deviceManager"; // Yeni eklenen yardımcı fonksiyon
import "./App.css";

initFontAwesome();

const App = () => {
  const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    manageDeviceId(isAuthenticated, user, getAccessTokenSilently);
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar />
      <LocationTracker />
      <div className="container flex-grow-1">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/profile" component={Profile} />
          <Route path="/vehiclelist" component={VehicleList} />
          <Route path="/vehicleorder" component={VehicleOrder} />
          <Route path="/vehicleorder1" component={VehicleOrder1} />
          <Route path="/addvehicletolist" component={AddVehicleToList} />
          <Route path="/createjob" component={CreateJob} />
          <Route path="/createnewlistno" component={CreateNewListNo} />
          <Route path="/addvehicletojob" component={AddVehicleToJob} />
          <Route path="/jobvehiclelist" component={JobVehicleList} />
          <Route path="/updatejobvehiclelist" component={UpdateJobVehicleList} />
          <Route path="/vehicledispatchpage" component={VehicleDispatchPage} />
          <Route path="/statusmanagment" component={StatusManagement} />
          <Route path="/operatormanagement" component={OperatorManagement} />
          <Route path="/operatordispatchespage" component={OperatorDispatchesPage} />
          <Route path="/userreportpage" component={UserReportPage} />
          <Route path="/reportdashboard" component={ReportDashboard} />
          <Route path="/driverstatuspage" component={DriverStatusPage} />
          <Route path="/operatorlocationpage" component={OperatorLocationPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
