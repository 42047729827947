import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Button, Input, Grid, Paper, Typography } from "@mui/material";

const VehicleList = () => {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      console.log('Fetching vehicles...');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleList`);
      console.log('Response:', response.data);
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleDeleteVehicle = React.useCallback(async (vehicle_id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/deleteVehicleFromList/${vehicle_id}`);
      setVehicles(vehicles.filter(vehicle => vehicle.vehicle_id !== vehicle_id));
    } catch (error) {
      console.error('Araç silinirken hata oluştu:', error);
    }
  }, [vehicles, setVehicles]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Plaka",
        accessor: "plaka"
      },
      {
        Header: "Şoför İsmi",
        accessor: "driver_name"
      },
      {
        Header: "Araç Tipi",
        accessor: "vehicle_type"
      },
      {
        Header: "Römork Tipi",
        accessor: "trailer_type"
      },
      {
        Header: "İşlemler",
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteVehicle(row.original.vehicle_id)}
          >
            Sil
          </Button>
        )
      }
    ],
    [handleDeleteVehicle] // Include handleDeleteVehicle in the dependency array
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: vehicles
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Araç Listesi
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={state.globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Ara..."
          fullWidth
          style={{ marginBottom: '10px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ overflowX: 'auto' }}>
          <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ border: '1px solid black', padding: '10px' }}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center', padding: '10px' }}>
                    Veri bulunamadı
                  </td>
                </tr>
              ) : (
                rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '10px' }}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default VehicleList;