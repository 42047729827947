import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const VehicleOrder = () => {
  const [vehicles, setVehicles] = useState([]);
  const [listNumbers, setListNumbers] = useState([]);  // To store distinct list numbers
  const [selectedListNumber, setSelectedListNumber] = useState(null);

  // Fetch vehicle list
  const fetchVehicleList = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleDispatchList`);
      setVehicles(response.data);
      
      // Extract unique list numbers for the dropdown
      const uniqueListNumbers = [...new Set(response.data.map(vehicle => vehicle.list_number))];
      setListNumbers(uniqueListNumbers);
      setSelectedListNumber(uniqueListNumbers[0]); // Default to the first list number
    } catch (error) {
      console.error('Error fetching vehicle list:', error);
    }
  }, []);

  useEffect(() => {
    fetchVehicleList();
  }, [fetchVehicleList]);

  // Filter vehicles by selected list number
  const filteredVehicles = selectedListNumber
    ? vehicles.filter(vehicle => vehicle.list_number === selectedListNumber)
    : vehicles;

  // Handle drag and drop
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedVehicles = Array.from(filteredVehicles);
    const [movedVehicle] = reorderedVehicles.splice(result.source.index, 1);
    reorderedVehicles.splice(result.destination.index, 0, movedVehicle);

    // Update the vehicles state to reflect the reordering
    setVehicles(prev =>
      prev.map(vehicle =>
        vehicle.list_number === selectedListNumber
          ? reorderedVehicles.find(v => v.dispatch_id === vehicle.dispatch_id)
          : vehicle
      )
    );

    // Send updated order to the server
    const updatedDispatches = reorderedVehicles.map((vehicle, index) => ({
      dispatch_id: vehicle.dispatch_id,
      order_number: index + 1, // Recalculate the order_number
    }));

    axios.put(`${process.env.REACT_APP_API_URL}/updateVehicleDispatchList`, { vehicles: updatedDispatches })
      .then(() => console.log('Order updated successfully'))
      .catch(err => console.error('Error updating order:', err));
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Araç Listesi</h2>

      {/* Dropdown to select list_number */}
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="list-number-select">Liste Seç:</label>
                <select
          id="list-number-select"
          value={selectedListNumber}
          onChange={(e) => setSelectedListNumber(Number(e.target.value))}
        >
          {listNumbers.sort((a, b) => b - a).map((listNumber) => (
            <option key={listNumber} value={listNumber}>
              {listNumber}
            </option>
          ))}
        </select>
      </div>

      {/* Drag and drop table */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="vehicleList">
          {(provided) => (
            <table className="vehicle-table" {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Plaka</th>
                  <th>Şoför</th>
                  <th>List Number</th> 
                  <th>Araç Tipi</th>
                  <th>Römork Tipi</th>
                </tr>
              </thead>
              <tbody>
                {filteredVehicles.map((vehicle, index) => (
                  <Draggable key={vehicle.dispatch_id} draggableId={String(vehicle.dispatch_id)} index={index}>
                    {(provided) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <td>{vehicle.order_number}</td>
                        <td>{vehicle.plaka}</td>
                        <td>{vehicle.driver_name}</td>
                        <td>{vehicle.list_number}</td>
                        <td>{vehicle.vehicle_type}</td>
                        <td>{vehicle.trailer_type}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default VehicleOrder;
