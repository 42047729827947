import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../App.css';  // CSS dosyası

const JobVehicleList = () => {
  const [vehicles, setVehicles] = useState([]);
  const [showAll, setShowAll] = useState(false);  // Tüm liste numaralarını gösterip göstermeme durumu
  const [selectedListNumber, setSelectedListNumber] = useState(null);  // Seçilen liste numarası

  // Araç listesini API'den çekmek için fonksiyon
  const fetchVehicleList = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleDispatchList`);
      const updatedVehicles = response.data.map(vehicle => ({
        ...vehicle,
        last_updated_at: formatDateToTR(vehicle.last_updated_at)  // Tarih formatını güncelliyoruz
      }));
      setVehicles(updatedVehicles);

      // En büyük list_number'ı seç
      const maxListNumber = Math.max(...updatedVehicles.map(vehicle => vehicle.list_number));
      setSelectedListNumber(maxListNumber);
    } catch (error) {
      console.error('Araç listesi alınırken hata oluştu:', error);
    }
  }, []);  // Boş bağımlılık dizisi, fonksiyonun yeniden oluşturulmasını engeller

  // Component mount olduğunda ve periyodik olarak veri çekmek için listeyi güncelle
  useEffect(() => {
    fetchVehicleList();  // İlk yüklemede veri çekme

    // 10 dakikada bir veri çekmek için zamanlayıcı ayarla (600000 milisaniye = 10 dakika)
    const intervalId = setInterval(() => {
      console.log('Araç listesi 5 dakikada bir güncelleniyor...');
      fetchVehicleList();
    }, 300000);

    // Bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchVehicleList]);  // fetchVehicleList artık bağımlılık olarak ekli

  // Tarihi Türkiye saatine göre formatlayan fonksiyon
  const formatDateToTR = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = {
      timeZone: 'Europe/Istanbul',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleString('tr-TR', options).replace(',', '');
  };

  // Gösterilecek liste numaralarını belirleyen fonksiyon
  const displayedListNumbers = showAll ? [...new Set(vehicles.map(vehicle => vehicle.list_number))] : [...new Set(vehicles.map(vehicle => vehicle.list_number))];
  
  // Seçilen liste numarasına ait araçları filtreleyen fonksiyon
  const filteredVehicles = selectedListNumber ? vehicles.filter(vehicle => vehicle.list_number === selectedListNumber) : vehicles;
  
  return (
    <div style={{ padding: '10px' }}>
      <h2>
        Liste: {displayedListNumbers.sort((a, b) => b - a).slice(0, 5).map((number, index) => (
          <button
            key={index}
            onClick={() => setSelectedListNumber(number)}
            style={{
              cursor: 'pointer',
              marginRight: '3px',
              padding: '3px 6px',
              borderRadius: '3px',
              border: '1px solid #ccc',
              backgroundColor: selectedListNumber === number ? '#007bff' : '#f8f9fa',
              color: selectedListNumber === number ? '#fff' : '#000',
              fontWeight: selectedListNumber === number ? 'bold' : 'normal'
            }}
          >
            {number}
          </button>
        ))}
        {!showAll && vehicles.length > 5 && ''}
        {!showAll && vehicles.length > 10 && (
          <button onClick={() => setShowAll(true)} style={{ marginLeft: '5px', padding: '3px 6px' }}>...</button>
        )}
      </h2>
      <table className="vehicle-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Plaka</th>
            <th>Şoför</th>
            <th>Durum</th>
            <th>Tarih</th>
            <th>Araç Tipi</th>
            <th>Römork Tipi</th>
          </tr>
        </thead>
        <tbody>
          {filteredVehicles.map((vehicle, index) => (
            <tr key={index} onClick={() => setSelectedListNumber(vehicle.list_number)}>
              <td>{vehicle.order_number}</td>
              <td>{vehicle.plaka}</td>
              <td>{vehicle.driver_name}</td>
              <td>{vehicle.status}</td>
              <td>{vehicle.last_updated_at}</td>
              <td>{vehicle.vehicle_type}</td>
              <td>{vehicle.trailer_type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobVehicleList;
