import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Typography, Box, Select, MenuItem, Button, Paper } from '@mui/material';

const DriverStatusPage = () => {
  const { user, isAuthenticated } = useAuth0(); // Auth0'dan kullanıcı bilgisi alınıyor
  const [dispatchData, setDispatchData] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [vehicleId, setVehicleId] = useState(null);
  const [selectedDispatchId, setSelectedDispatchId] = useState(null); // Seçilen dispatch_id

  useEffect(() => {
    const fetchDispatchData = async () => {
      if (!isAuthenticated || !user?.email) return;
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getDispatchesByEmail`, {
          params: { email: user.email }
        });
  
        const filteredDispatchData = response.data.filter(dispatch => dispatch.status && dispatch.status !== 'ÇIKTI');
        setDispatchData(filteredDispatchData);
  
        // 'YÜKLENDİ' veya 'FABRİKADA' durumuna göre işlem yap
        const maxYuklendiDispatch = filteredDispatchData
          .filter(dispatch => dispatch.status === 'YÜKLENDİ')
          .reduce((max, dispatch) => dispatch.dispatch_id > max ? dispatch.dispatch_id : max, 0);
  
        const maxFabrikadaDispatch = filteredDispatchData
          .filter(dispatch => dispatch.status === 'FABRİKADA')
          .reduce((max, dispatch) => dispatch.dispatch_id > max ? dispatch.dispatch_id : max, 0);
  
        if (maxYuklendiDispatch) {
          const selectedDispatch = filteredDispatchData.find(dispatch => dispatch.dispatch_id === maxYuklendiDispatch);
          setVehicleId(selectedDispatch.vehicle_id);
          setSelectedDispatchId(selectedDispatch.dispatch_id);
          setNewStatus('FABRİKADA');
        } else if (maxFabrikadaDispatch) {
          const selectedDispatch = filteredDispatchData.find(dispatch => dispatch.dispatch_id === maxFabrikadaDispatch);
          setVehicleId(selectedDispatch.vehicle_id);
          setSelectedDispatchId(selectedDispatch.dispatch_id);
          setNewStatus('ÇIKTI');
        }
      } catch (error) {
        console.error('Hata:', error);
      }
    };
  
    fetchDispatchData();
  }, [isAuthenticated, user]);
  
  const handleStatusUpdate = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/updateVehicleStatusByDriver`, {
        vehicleId: vehicleId || null,
        status: newStatus || null,
        email: user.email || null,
        dispatchId: selectedDispatchId || null
      });
  
      if (response.status === 200) {
        console.log('Durum başarıyla güncellendi.');
        window.location.reload();
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
    <Container maxWidth="sm" style={{ padding: '16px' }}>
      <Paper elevation={3} style={{ padding: '16px', marginTop: '24px' }}>
        <Typography variant="h4" align="center" gutterBottom>
          Durum Güncelle
        </Typography>
  
        {dispatchData.length > 0 ? (
          dispatchData.map((dispatch) => (
            <Box key={dispatch.dispatch_id} mb={2}>
              <Typography variant="h6">
                Araç ID: {dispatch.vehicle_id} - Dispatch_id: {dispatch.dispatch_id}
              </Typography>
              <Typography variant="subtitle1">
                Son Durum: {dispatch.status}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body1">Henüz güncellencek bir durum yok.</Typography>
        )}
  
        {newStatus && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Yeni Durum:
            </Typography>
            <Select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              fullWidth
              variant="outlined"
              style={{ marginBottom: '16px' }}
            >
              <MenuItem value="FABRİKADA">FABRİKADA</MenuItem>
              <MenuItem value="ÇIKTI">ÇIKTI</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleStatusUpdate}
              style={{ marginTop: '16px' }}
            >
              Güncelle
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );  
}

export default DriverStatusPage;
