import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateNewListNo = () => {
  const [shiftCount, setShiftCount] = useState(10); // Default shift value
  const [listNumbers, setListNumbers] = useState([]);

  useEffect(() => {
    // Fetch list numbers
    const fetchListNumbers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/listNumbers');
        setListNumbers(response.data);
      } catch (error) {
        console.error('Error fetching list numbers:', error);
      }
    };

    fetchListNumbers();
  }, []);

  const handleCreateNewListNo = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/createNewListNo', { shiftCount });
      alert(response.data);
      // Refresh list numbers after creating a new list
      const updatedListNumbers = await axios.get(process.env.REACT_APP_API_URL + '/listNumbers');
      setListNumbers(updatedListNumbers.data);
    } catch (error) {
      console.error('Error creating new list number:', error);
      alert('Yeni Liste Oluşturulamadı');
    }
  };

  const handleDeleteListNumber = async (listNumber) => {
    const confirmDelete = window.confirm(`${listNumber} numaralı listeyi silmek istediğinize emin misiniz?`);
    if (confirmDelete) {
      try {
        await axios.delete(process.env.REACT_APP_API_URL + `/deleteListNumber/${listNumber}`);
        // Refresh list numbers after deletion
        const updatedListNumbers = await axios.get(process.env.REACT_APP_API_URL + '/listNumbers');
        setListNumbers(updatedListNumbers.data);
      } catch (error) {
        console.error('Error deleting list number:', error);
        alert('Liste Silinemedi');
      }
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Yeni Liste Oluştur</h2>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Listeyi kaç sıra atlatmak istersiniz?
          <input
            type="number"
            value={shiftCount}
            onChange={(e) => setShiftCount(Number(e.target.value))}
            style={{ marginLeft: '10px', padding: '5px', fontSize: '16px' }}
          />
        </label>
      </div>
      <button onClick={handleCreateNewListNo} style={{ padding: '10px 20px', fontSize: '16px' }}>
        Yeni Liste Oluştur
      </button>

      <h2>Mevcut Listeler</h2>
      <ul>
        {listNumbers
          .sort((a, b) => b - a) // Sort in descending order
          .map((listNumber) => (
            <li key={listNumber}>
              {listNumber}
              <button
                onClick={() => handleDeleteListNumber(listNumber)}
                style={{ marginLeft: '10px', padding: '5px 10px', fontSize: '14px' }}
              >
                Sil
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CreateNewListNo;
