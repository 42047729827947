import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid'; // Material UI DataGrid'i kullanıyoruz


const UpdateJobVehicleList = () => {
  const [vehicles, setVehicles] = useState([]);

  // Araç verilerini çekmek için API çağrısı
  useEffect(() => {
    const fetchVehicleList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleDispatchList2`);
        setVehicles(response.data);
      } catch (error) {
        console.error('Veri çekilirken hata oluştu:', error);
      }
    };

    fetchVehicleList();
  }, []);

  // Satır güncelleme işlemi
  const handleProcessRowUpdate = async (updatedRow) => {
    try {
      // No need to manually format dates, just send the row as is
      await axios.put(`${process.env.REACT_APP_API_URL}/updateVehicleDispatchList2`, {
        vehicles: [updatedRow],
      });
  
      return updatedRow;
    } catch (error) {
      console.error('Güncelleme sırasında hata oluştu:', error);
      throw error;
    }
  };


  const columns = [
    { field: 'list_number', headerName: 'Liste', width: 50 },
    { field: 'order_number', headerName: 'Sıra', width: 50 }, 
    { field: 'operator_id', headerName: 'Op', width: 50 },
    { field: 'plaka', headerName: 'Plaka', width: 100 },
    { field: 'driver_name', headerName: 'Şoför', width: 150 },
    { field: 'status', headerName: 'Durum', width: 100, editable: true }, 
    { field: 'assigned_at', headerName: 'Atanma', width: 150, editable: true }, 
    { field: 'loaded_at', headerName: 'Yükleme', width: 150, editable: true }, 
    { field: 'arrived_at', headerName: 'Varış', width: 150, editable: true }, 
    { field: 'exited_at', headerName: 'Çıkış', width: 150, editable: true }, 
    { field: 'trip_count', headerName: 'Sefer', width: 50, editable: true }, 
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <h2>Araç Listesi Güncelle</h2>
      <DataGrid
        rows={vehicles}
        columns={columns}
        pageSize={100}
        getRowId={(row) => row.dispatch_id}
        processRowUpdate={handleProcessRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          '& .MuiDataGrid-root': {
            fontSize: '9px', // Yazı fontunu 9px'e indiriyoruz
          },
          '& .MuiDataGrid-cell': {
            lineHeight: '1', // Satır yüksekliğini düşürüyoruz
            padding: '8px 4px', // Hücre içi boşlukları ayarlıyoruz
          },
          '& .MuiDataGrid-row': {
            maxHeight: '30px', // Satır yüksekliği 30px olacak şekilde
          },
        }}
      />
    </div>
  );
};

export default UpdateJobVehicleList;
