import React, { Component } from "react";


class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">S.S. YEŞİLHİSAR TAŞIMA VE İŞLETME KOOPERATİFİ</h2>
      </div>
    );
  }
}

export default Content;
