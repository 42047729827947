import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Table
} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from "axios";
import Loading from "../components/Loading.js";
import PhoneInput from 'react-phone-input-2'; // Telefon numarası girişi için kütüphane
import 'react-phone-input-2/lib/style.css';
import authConfig from "../auth_config.json";


const ProfileComponent = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    plaka: '',
    driver_name: '',
    driver_phone: '',
    owner_name: '',
    owner_phone: '',
    vehicle_type: '',
    trailer_type: ''
  });

  const fetchUserData = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const userInfoResponse = await axios.get(`https://${authConfig.domain}/userinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userInfo = userInfoResponse.data;
      const email = userInfo.email;

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserDetails`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-User-Email': email
        },
      });

      if (response.data) {
        setEditData(response.data);
      }
    } catch (error) {
      console.error('Kullanıcı verisi alınırken hata oluştu:', error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated, fetchUserData]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const turkishToUpper = (str) => {
    const letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç" };
    str = str.replace(/i|ş|ğ|ü|ö|ç/g, (letter) => letters[letter]);
    return str.toUpperCase();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'plaka') {
      setEditData({
        ...editData,
        [name]: value.replace(/\s/g, '').toUpperCase(),
      });
    } else if (name === 'owner_name' || name === 'driver_name') {
      setEditData({
        ...editData,
        [name]: turkishToUpper(value),
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };

  const handlePhoneChange = (value, name) => {
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/saveOrUpdateUserDetails`,
        {
          plaka: editData.plaka,
          driver_name: editData.driver_name,
          driver_phone: editData.driver_phone,
          owner_name: editData.owner_name,
          owner_phone: editData.owner_phone,
          vehicle_type: editData.vehicle_type,
          trailer_type: editData.trailer_type,
          email: user.email
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        console.log('Yeni kullanıcı kaydedildi.');
      } else if (response.status === 200) {
        console.log('Kullanıcı bilgileri güncellendi.');
      }
      setModalOpen(false);
    } catch (error) {
      console.error("Bilgiler güncellenirken hata oluştu:", error);
    }
  };

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <h2>{user.email}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="primary" onClick={toggleModal}>
            Bilgilerimi Güncelle
          </Button>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} md={6} className="mx-auto">
          <Table bordered responsive size="sm" className="table-custom">
            <tbody>
              <tr>
                <td><strong>Plaka</strong></td>
                <td>{editData.plaka || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Şoför Ad Soyad</strong></td>
                <td>{editData.driver_name || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Şoför Telefonu</strong></td>
                <td>{editData.driver_phone || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Araç Sahibi</strong></td>
                <td>{editData.owner_name || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Araç Sahibi Telefonu</strong></td>
                <td>{editData.owner_phone || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Araç Tipi</strong></td>
                <td>{editData.vehicle_type || 'Veri Yok'}</td>
              </tr>
              <tr>
                <td><strong>Römork Tipi</strong></td>
                <td>{editData.trailer_type || 'Veri Yok'}</td>
              </tr>
            </tbody>
          </Table>

        </Col>
      </Row>

      {/* Modal for updating user information */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Bilgileri Güncelle</ModalHeader>
        <p>Eğer Araç Sahibi iseniz her iki alanada kendi bilgilerinizi giriniz. </p>
        <p>Eğer Şoförseniz önce kendi bilgilerinizi sonra Araç Sahibi veya Firma Bilgilerini giriniz.</p>
        <ModalBody>
          <Input
            type="text"
            name="plaka"
            placeholder="Plaka"
            value={editData.plaka || ""}
            onChange={handleInputChange}
            required
          />
          <Input
            type="text"
            name="driver_name"
            placeholder="Şoför Ad Soyad"
            value={editData.driver_name || ""}
            onChange={handleInputChange}
            required
          />
          <PhoneInput
            country={'tr'}
            value={editData.driver_phone || ""}
            onChange={(value) => handlePhoneChange(value, 'driver_phone')}
            placeholder="Şoför Telefonu"
            required
          />
          <Input
            type="text"
            name="owner_name"
            placeholder="Araç Sahibi veya Firma Adı"
            value={editData.owner_name || ""}
            onChange={handleInputChange}
            required
          />
          <PhoneInput
            country={'tr'}
            value={editData.owner_phone || ""}
            onChange={(value) => handlePhoneChange(value, 'owner_phone')}
            placeholder="Araç Sahibi Telefonu"
            required
          />

          {/* Araç Tipi Seçimi */}
          <Input
            type="select"
            name="vehicle_type"
            value={editData.vehicle_type || ""}
            onChange={handleInputChange}
          >
            <option value="">Araç Tipini Seçin</option>
            <option value="TIR">TIR</option>
            <option value="KIRKAYAK">KIRKAYAK</option>
            <option value="ONTEKER">ONTEKER</option>
          </Input>

          {/* Römork Tipi Seçimi */}
          <Input
            type="select"
            name="trailer_type"
            value={editData.trailer_type || ""}
            onChange={handleInputChange}
          >
            <option value="">Römork Tipini Seçin</option>
            <option value="DAMPER">DAMPER</option>
            <option value="SAL DORSE">SAL DORSE</option>
            <option value="KASALI">KASALI</option>
          </Input>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdate}>
            Güncelle
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            İptal
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});