import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import debounce from 'lodash.debounce';

const LocationTracker = () => {
  const { user, isAuthenticated } = useAuth0();
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [hasEnteredGeofence, setHasEnteredGeofence] = useState(false);
  const [dispatchId, setDispatchId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const targetLatitude = 39.14400377301225; // Hedef alanın enlem değeri
  const targetLongitude = 35.206112996113454; // Hedef alanın boylam değeri
  const radius = 1000; // metre cinsinden (1 km)

  // Kullanıcının dispatchId ve vehicleId bilgilerini almak için
  useEffect(() => {
    const fetchDispatchData = async () => {
      if (!isAuthenticated || !user?.email) return;

      try {
        const response = await axios.get(`${apiUrl}/getDispatchesByEmail`, {
          params: { email: user.email }
        });

        if (response.data.length > 0) {
          const filteredDispatchData = response.data.filter(dispatch => dispatch.status && dispatch.status !== 'ÇIKTI');
          const maxDispatch = filteredDispatchData.reduce((max, dispatch) => 
            dispatch.dispatch_id > max.dispatch_id ? dispatch : max, filteredDispatchData[0]);

          if (maxDispatch) {
            setVehicleId(maxDispatch.vehicle_id);
            setDispatchId(maxDispatch.dispatch_id);
          }
        }
      } catch (error) {
        console.error('Veri alınırken hata oluştu:', error.message);
      }
    };

    fetchDispatchData();
  }, [isAuthenticated, user, apiUrl]);

  // Geofence kontrolü ve güncelleme işlevi
  const handleGeofence = useCallback(async (latitude, longitude) => {
    const distance = getDistanceFromLatLonInMeters(targetLatitude, targetLongitude, latitude, longitude);
    console.log(`Hesaplanan mesafe: ${distance} metre`);

    if (distance <= radius && !hasEnteredGeofence) {
      if (!vehicleId || !dispatchId || !user?.email) {
        console.warn("Araç ID, görev ID veya kullanıcı bilgisi eksik olduğu için durumu güncellemeden önce bekleniyor...");
        return;
      }

      setHasEnteredGeofence(true);
      console.log("Kullanıcı hedef alana girdi, durumu 'FABRİKADA' olarak güncelliyor...");
      await updateVehicleStatus('FABRİKADA');
    } else if (distance > radius && hasEnteredGeofence) {
      setHasEnteredGeofence(false);
      console.log("Kullanıcı hedef alandan çıktı, durumu 'ÇIKTI' olarak güncelliyor...");
      await updateVehicleStatus('ÇIKTI');
    }
  }, [targetLatitude, targetLongitude, radius, hasEnteredGeofence, vehicleId, dispatchId, user]);

  const updateVehicleStatus = useCallback(async (status) => {
    if (!vehicleId || !dispatchId || !user?.email) {
      console.warn("Araç ID, görev ID veya kullanıcı bilgisi eksik, durum güncellenemiyor.");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/updateVehicleStatusByDriver`, {
        vehicleId,
        status,
        email: user.email,
        dispatchId,
      });

      if (response.status === 200) {
        console.log(`Araç durumu başarıyla güncellendi: ${status}`);
      }
    } catch (error) {
      console.error("Durum güncellenirken hata oluştu:", error.message);
    }
  }, [vehicleId, dispatchId, user, apiUrl]);

  useEffect(() => {
    const requestLocationPermission = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Konum izni verildiğinde konumu takip etmeye başla
            const watchId = navigator.geolocation.watchPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                setPosition({ latitude, longitude });

                // Geofence kontrolü
                handleGeofence(latitude, longitude);
              },
              (error) => {
                console.error("Konum alınırken hata oluştu:", error.message);
              },
              { enableHighAccuracy: true, maximumAge: 0 }
            );

            return () => navigator.geolocation.clearWatch(watchId);
          },
          (error) => {
            // Konum izni reddedildiğinde yeniden izin iste
            if (error.code === error.PERMISSION_DENIED) {
              console.warn("Konum izni reddedildi, 5 saniye sonra tekrar izin isteniyor...");
              setTimeout(requestLocationPermission, 5000); // 5 saniye sonra tekrar izin iste
            }
          }
        );
      } else {
        console.error("Geolocation tarayıcınızda desteklenmiyor.");
      }
    };

    requestLocationPermission();
  }, [handleGeofence]);

  const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Dünya yarıçapı (metre)
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  return null; // Kullanıcının konumunu göstermiyoruz, JSX kısmını kaldırdık
};

export default LocationTracker;
