import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, MenuItem, Button, Typography } from '@mui/material';

const OperatorLocationPage = () => {
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [location, setLocation] = useState({ latitude: '', longitude: '' });

  useEffect(() => {
    const fetchOperators = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/operators`);
      setOperators(response.data);
    };
    fetchOperators();
  }, []);

  const handleOperatorChange = async (event) => {
    const operatorId = event.target.value;
    setSelectedOperator(operatorId);

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/operator/${operatorId}/location`);
    setLocation(response.data);
  };

  const openInMaps = () => {
    const { latitude, longitude } = location;
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Operatör Konumları</Typography>
      <Select value={selectedOperator || ''} onChange={handleOperatorChange} fullWidth>
        {operators.map((operator) => (
          <MenuItem key={operator.operator_id} value={operator.operator_id}>
            {operator.operator_name}
          </MenuItem>
        ))}
      </Select>
      {location.latitude && location.longitude && (
        <Button
          variant="contained"
          color="primary"
          onClick={openInMaps}
          style={{ marginTop: '20px' }}
        >
          Konumu Haritada Aç
        </Button>
      )}
    </div>
  );
};

export default OperatorLocationPage;
