import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, TextField, Button, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Grid2 from '@mui/material/Grid2';

const OperatorManagement = () => {
  const [operators, setOperators] = useState([]);
  const [newOperator, setNewOperator] = useState({
    operator_name: '',
    machine_name: '',
    driver_id: '',
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/operators`);
        setOperators(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchOperators();
  }, []);

  const handleAddOperator = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/operators`, newOperator);
      setOperators([...operators, response.data]);
      setNewOperator({ operator_name: '', machine_name: '', driver_id: '', latitude: '', longitude: '' });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRowEditCommit = async (params) => {
    try {
      const updatedOperator = {
        ...operators.find((op) => op.operator_id === params.id),
        [params.field]: params.value, // Değiştirilen alanı güncelle
      };
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/operators/${params.id}`,
        updatedOperator
      );
  
      console.log('Operatör güncellendi:', response.data);
  
      // Operatör listesini güncelle
      setOperators((prevOperators) =>
        prevOperators.map((op) =>
          op.operator_id === params.id ? response.data : op
        )
      );
    } catch (error) {
      console.error('Güncelleme hatası:', error);
    }
  };
  

  const columns = [
    { field: 'operator_name', headerName: 'Operatör Adı', width: 150, editable: true },
    { field: 'machine_name', headerName: 'Makina Adı', width: 150, editable: true },
    { field: 'driver_id', headerName: 'Kullanıcı ID', width: 150, editable: true },
    { field: 'latitude', headerName: 'Enlem', width: 150, editable: true },
    { field: 'longitude', headerName: 'Boylam', width: 150, editable: true },
    {
      field: 'actions',
      headerName: 'İşlemler',
      width: 150,
      renderCell: () => (
        <Button variant="contained" color="secondary" size="small" disabled>
          SİL
        </Button>
      ),
    },
  ];
  

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ fontSize: '1.5rem' }}>
        Operatör Yönetimi
      </Typography>
      <Box mb={2} p={2} style={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Operatör Adı"
              value={newOperator.operator_name}
              onChange={(e) => setNewOperator({ ...newOperator, operator_name: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Makina Adı"
              value={newOperator.machine_name}
              onChange={(e) => setNewOperator({ ...newOperator, machine_name: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Kullanıcı ID"
              value={newOperator.driver_id}
              onChange={(e) => setNewOperator({ ...newOperator, driver_id: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Enlem"
              value={newOperator.latitude}
              onChange={(e) => setNewOperator({ ...newOperator, latitude: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid2>
          <Grid2 item xs={12} sm={4}>
            <TextField
              label="Boylam"
              value={newOperator.longitude}
              onChange={(e) => setNewOperator({ ...newOperator, longitude: e.target.value })}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid2>
          <Grid2 item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddOperator} fullWidth size="small">
              Ekle
            </Button>
          </Grid2>
        </Grid2>
      </Box>
      <Paper style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={operators}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onRowEditCommit={handleRowEditCommit}
          getRowId={(row) => row.operator_id}
        />
      </Paper>
    </Container>
  );
};

export default OperatorManagement;
