import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { Table, Button } from "reactstrap";

const AddVehicleToList = () => {
  const [vehicles, setVehicles] = useState([]);  // Dropdown için
  const [selectedVehicles, setSelectedVehicles] = useState([]);  // Seçilen araçlar
  const [vehicleList, setVehicleList] = useState([]);  // Listeye eklenen araçlar

  // Araçları çek (vehicle_lists'te olmayanlar)
  useEffect(() => {
    fetchAvailableVehicles();
    fetchVehicleList();
  }, []);

  // Mevcut araç listesini çek
  const fetchAvailableVehicles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicles`);
      setVehicles(response.data.map(vehicle => ({
        value: vehicle.vehicle_id,
        label: `${vehicle.plaka} - ${vehicle.driver_name}`
      })));
    } catch (error) {
      console.error("Araçlar alınırken hata oluştu:", error);
    }
  };

  // Listeye eklenen araçları çek
  const fetchVehicleList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getVehicleList`);

      // Check if response.data is an array, if not, default to an empty array
      setVehicleList(Array.isArray(response.data) ? response.data : []);

    } catch (error) {
      console.error("Error fetching the vehicle list:", error);
      setVehicleList([]);  // On error, set vehicleList to an empty array
    }
  };

  // Araçları ekle
  const handleAddVehicles = async () => {
    if (selectedVehicles.length === 0) {
      alert("Please select at least one vehicle.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/addVehiclesToList`, {
        vehicles: selectedVehicles.map(vehicle => ({
          vehicle_id: vehicle.value
        }))
      });

      fetchAvailableVehicles();
      fetchVehicleList();
      setSelectedVehicles([]);
    } catch (error) {
      console.error("Error adding vehicles:", error);
    }
  };

  // Araç listesinde silme işlemi
  const handleDelete = async (vehicleId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/deleteVehicleFromList/${vehicleId}`);
      fetchVehicleList();  // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting vehicle:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Araçları Listeye Ekle</h2>
      <Select
        options={vehicles}
        value={selectedVehicles}
        onChange={(selected) => setSelectedVehicles(selected || [])}  // Seçim değişikliklerini güncelle
        placeholder="Araç seçin"
        isMulti
      />

      <Button color="primary" onClick={handleAddVehicles} style={{ marginTop: "20px" }}>
        Araçları Ekle
      </Button>

      {/* Araç Listesi - CRUD Tablolu */}
      <h2 className="mt-5">Araç Listesi</h2>
      <Table bordered hover responsive className="mt-3">
        <thead className="thead-dark">
          <tr>
            <th>Plaka</th>
            <th>Sürücü Adı</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(vehicleList) && vehicleList.map(vehicle => (
            <tr key={vehicle.vehicle_id}>
              <td>{vehicle.plaka}</td>
              <td>{vehicle.driver_name}</td>
              <td>
                <Button color="danger" onClick={() => handleDelete(vehicle.vehicle_id)}>
                  Sil
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AddVehicleToList;
