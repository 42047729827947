import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const manageDeviceId = async (isAuthenticated, user, getAccessTokenSilently) => {
  if (isAuthenticated && user) {
    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceId", deviceId);
    }

    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_API_URL}/verifyDevice`,
        {
          deviceId,
          userEmail: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.error("Cihaz kimliği doğrulanırken hata oluştu:", err);
    }
  }
};

export default manageDeviceId;
